import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../utils/loader.js';
import axios from 'axios';
import Config from '../config/env.js'
import API from "../utils/apiCalling.js";


function ManpowerUpload() {

    const [loader, setLoader] = useState(false)
    const [file, setFile] = useState('')
    const [data, setData] = useState([]);
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [partnerName, setpartnerName] = useState('')
    const [branchName, setBranchName] = useState('')

    const api = new API


    const handleFileChange = (event) => {
        setFile(event.target.files[0])
    };

    const uploadExcel = async () => {
        if (!file) {
            toast.error('Please select a file to upload');
            return;
        }

        setLoader(true);

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post(`${Config.new.site_api}/Avanti/uploadManpowerData`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.code === 200) {
                toast.success('Upload successfully');
            } else {
                toast.error('Upload failed');
            }
        } catch (error) {
            toast.error('API Error: ' + error.message);
        } finally {
            setLoader(false);
        }
    };


    // Download Manpower


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoader(true)
        // console.log(data, "llllllllllllll")
        try {
            let response = await api.get(`${Config.new.site_api}/Avanti/getManpowerSurveyDetails`);
            // console.log("   response  ", response)
            if (response) {
                setData(response.data[0].questions);
                setLoader(false)
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoader(false)
        }
    };

    const downloadExcel = async () => {
        setLoader(true)
        let data = {
            survey_id: '672c5e45b474b9d630911372'
        };
        try {
            let response = await fetch(`${Config.new.site_api}/Avanti/getAvantiManpowerSurveyWiseResponse`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });
            if (response.ok) {
                setLoader(false);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'Manpower.xlsx';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                setLoader(false);
                console.error('Failed to download file');
            }

        }
        catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    const findManpowerData = async () => {
        console.log(partnerName, branchName, startDate, endDate)
    }

    const clearManpowerFilterData = async () => {
        document.querySelectorAll("#input").forEach((val) => {

            val.value = ""
          })
    }

    return (
        <>

            {loader && <Loader />}
            <ToastContainer />
            <div className="container mt-4">
                <div className="mb-5 text-center">
                    <h4 className="mb-3">Edit Manpower</h4>
                    <a className="btn btn-primary" href="/editManpower" target="_blank">Edit Manpower</a>
                </div>

                <hr />

                <div className="mb-5 text-center">
                    <h4 className="mb-3">Download Manpower</h4>
                    <button onClick={() => downloadExcel()} className="btn btn-success">Download Manpower Data</button>
                </div>

                {/* <div className="mb-5">
                    <h4 className="mb-3">Download Manpower Data</h4>
                    <div className="container mt-4">
                        <div className="row align-items-center">
                            <div className="col-md-8 d-flex gap-2 mb-3">
                                <input
                                    className="form-control"
                                    type="date"
                                    id="input"
                                    name="startDate"
                                    onChange={(e)=>{setStartDate(e.target.value)}}
                                />
                                <span className="d-flex align-items-center mx-2">to</span>
                                <input
                                    className="form-control"
                                    type="date"
                                    id="input"
                                    name="endDate"
                                    onChange={(e)=>{setEndDate(e.target.value)}}
                                />
                            </div>
                            <div className="col-md-4 mb-3">
                                <input
                                    type="text"
                                    id="input"
                                    className="form-control"
                                    placeholder="Enter Partner Name"
                                    value={partnerName}
                                    onChange={(e)=>{setpartnerName(e.target.value)}}
                                />
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-md-4 mb-3">
                                <input
                                    type="text"
                                    id="input"
                                    className="form-control"
                                    placeholder="Enter Branch Name"
                                    value={branchName}
                                    onChange={(e)=>{setBranchName(e.target.value)}}
                                />
                            </div>
                            <div className="col-md-4 mb-3">
                                <button className="mainbtn w-100" onClick={()=>{findManpowerData()}}>Find</button>
                            </div>
                            <div className="col-md-4 mb-3">
                                <button className="mainbtn w-100" onClick={()=>{clearManpowerFilterData()}}>Clear Filter</button>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <button
                            onClick={() => downloadExcel()}
                            className="btn btn-success mr-3 w-25"
                        >
                            Download Manpower Data
                        </button>
                    </div>
                </div> */}


                <hr />

                <div className="mb-5 text-center">
                    <h4 className="mb-3">Upload Manpower Data</h4>
                    <div className="d-flex justify-content-center align-items-center">
                        <input type="file" accept=".xlsx, .xls" className="form-control w-50" onChange={(e) => handleFileChange(e)} />
                        <button className="btn btn-info ml-3" onClick={() => uploadExcel()}>Upload</button>
                    </div>
                </div>
            </div>

            <hr />
        </>
    )
}

export default ManpowerUpload
